<template>
  <div id="app">
    <div class="calculator-container">
      <h1>Izracunaj visinu stola</h1>
      <div class="input-group">
        <label for="userHeight">Tvoja visina (cm):</label>
        <input v-model.number="userHeight" type="number" id="userHeight" />
      </div>
      <div class="input-group">
        <label for="position">Pozicija:</label>
        <select v-model="position" id="position">
          <option value="sitting">Sedim</option>
          <option value="standing">Stojim</option>
        </select>
      </div>
      <button @click="calculateDeskHeight">Calculate</button>
      <div v-if="deskHeight !== null" class="result">
        Preporucena visina stola je: <strong>{{ deskHeight }}</strong> cm
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      userHeight: 0,
      position: "sitting",
      deskHeight: null,
    };
  },
  methods: {
    calculateDeskHeight() {
      // Adjust the calculation based on the selected position
      if (this.position === "sitting") {
        this.deskHeight = (this.userHeight * 0.4).toFixed(2);
      } else if (this.position === "standing") {
        // A more nuanced calculation for standing desk height
        // Starting with a base height of 100 cm for an average height (around 170 cm)
        // and adjusting proportionally for different heights
        let baseHeight = 100; // Base height for standing desk in cm
        let heightAdjustment = (this.userHeight - 170) * 0.1; // Adjust 0.1 cm for every cm above or below 170 cm
        this.deskHeight = (baseHeight + heightAdjustment).toFixed(2);
      }
    },
  },
};
</script>

<style>
#app {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #000000 0%, #000000 100%);
  color: white;
  text-align: center;
}

.calculator-container {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.input-group {
  margin-bottom: 20px;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #2575fc;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #1a5fb4;
}

.result {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 5px;
}
</style>
